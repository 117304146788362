<template>
  <div>
    <div v-if="!isDetails">
      <div class="card" style="height: 40px">
        <div style="flex: 0; display: flex; float: left">
          <div style="width: 80px; height: 40px; margin-right: 25px">
            <el-button type="primary" style="width" @click="handleAdd"
              >新增会员</el-button
            >
          </div>
          <div style="width: 80px; height: 40px; margin-right: 25px">
            <el-button type="primary" style="width" @click="handleAssignMember"
              >分配会员</el-button
            >
          </div>
          <div style="width: 80px; height: 40px; margin-right: 40px">
            <el-button type="primary" style="width" @click="handleSetWaitAssign"
              >设为待分配</el-button
            >
          </div>
          <div style="width: 80px; height: 40px; margin-right: 25px">
            <el-button type="primary" style="width" @click="handleTakeUser"
              >领取会员</el-button
            >
          </div>
          <div style="width: 80px; height: 40px; margin-right: 25px">
            <el-button type="primary" @click="setIntegral">积分设置</el-button>
          </div>
          <div style="width: 60px; height: 40px; margin-right: 60px">
            <el-button type="primary" @click="setSavings">储值金充值</el-button>
          </div>
          <div style="width: 60px; height: 40px; margin-right: 60px">
            <Send :client_user_ids="client_user_id"  :client_user_master_ids="client_user_master_ids" />
          </div>
          <div style="width: 70px; height: 40px; margin-right: 25px">
            <el-button
              type="primary"
              plain
              icon="el-icon-upload2"
              :loading="exportLoading"
              @click="handleExport()"
              >导出</el-button
            >
          </div>
          <div style="width: 125px; height: 40px; margin-right: 8px">
              <el-button type="primary" plain @click="getImportFile"
                >下载导入模板</el-button
              >
            </div>
            <div style="width: 80px; height: 40px">
              <el-upload
                :action="baseUrl + '/store/customer.MineUser/userImport'"
                name="file"
                :headers="{
                  authorization: token,
                }"
                :data="{
                  high_seas: 'high_seas'
                }"
                :show-file-list="false"
                :on-success="handleImportSuccess"
                style="width: 80px; height: 40px"
              >
                <el-button type="primary" icon="el-icon-download" plain
                  >导入</el-button
                >
              </el-upload>
            </div>
        </div>
        <div style="float: right; display: flex">
          <SearchConf function_code="clientuser" />
          <TableConf function_code="clientuser" @onRowList="handleRowList" />
          <ExportConf function_code="clientuser" />
        </div>
      </div>
      <div
        style="
          padding: 10px 0;
          border: 1px solid #e8e8e8;
          border-top: none;
          background: white;
          border-radius: 0 0 5px 5px;
        "
      >
        <Search @onCond="handleCond" function_code="clientuser" />
      </div>
      <div
        class="table"
        style="border-top: 1px solid #e8e8e8; margin-top: 15px"
        v-if="rowList.length !== 0"
      >
        <el-table
          ref="multipleTable"
          :data="tableData"
          stripe
          tooltip-effect="dark"
          height="660"
          @selection-change="handleSelectionChange"
          v-loading="tableLoading"
          @row-dblclick="handleDalclick"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <template v-for="(item, index) in rowList">
            <el-table-column
              align="center"
              :key="index"
              :prop="item.field_alias"
              :label="item.field_text"
              :width="item.t_width"
              :filters="item.filed_status"
            >
              <template slot-scope="scope">
                <div>
                  <div v-if="item.field_alias === 'str_tag_name'">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="scope.row[item.field_alias]"
                      placement="top"
                    >
                      <div
                        style="
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                        "
                      >
                        {{ scope.row[item.field_alias] }}
                      </div>
                    </el-tooltip>
                  </div>
                  <div v-else-if="item.field_alias === 'realname'">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="scope.row[item.field_alias]"
                      placement="top"
                    >
                      <div
                        style="
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                        "
                      >
                        {{ scope.row[item.field_alias] }}
                      </div>
                    </el-tooltip>
                  </div>
                  <div v-else>
                    {{ scope.row[item.field_alias] }}
                  </div>
                </div>
              </template></el-table-column
            >
          </template>
          <el-table-column
            align="center"
            fixed="right"
            prop="operation"
            label="操作"
            width="180"
          >
            <template slot-scope="scope">
              <div class="options">
                <el-select
                  v-model="scope.row.select"
                  placeholder="请选择"
                  @change="handleDetails($event, scope.row)"
                >
                  <el-option label="详情" :value="1"></el-option>
                  <el-option label="编辑" :value="2"></el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagina">
        <el-pagination
          background
          :current-page="page"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          :page-sizes="[15, 30, 40, 50]"
          :page-size="15"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
    <el-dialog
      :visible.sync="assignVisiable"
      width="30%"
      title="分配会员"
      :destroy-on-close="true"
      :before-close="cancelAssign"
    >
      <div>
        <el-form :model="follow_form" :rules="follow_rules" ref="follow_form">
          <el-form-item label="跟进人" label-width="100px">
            <el-select
              prop="follow_store_user_id"
              v-model="follow_form.follow_store_user_id"
              placeholder="请选择跟进人"
            >
              <template v-for="(item, index) in selectUserList">
                <el-option
                  :label="item.realname"
                  :value="item.store_user_id"
                  :key="index"
                  >{{ item.realname }}（已分配{{
                    item.follow_num
                  }}位）</el-option
                >
              </template>
            </el-select>
          </el-form-item>
          <div style="text-align: center">
            <el-button type="primary" @click="sureAssignUser('follow_form')"
              >确定</el-button
            >
            <el-button @click="cancelAssign">取消</el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="addVisiable"
      width="60%"
      :title="type === 'add' ? '新增会员' : '编辑会员'"
      :destroy-on-close="true"
      :before-close="cancelAdd"
    >
      <el-form :model="addForm" :rules="rules" ref="form">
        <div class="form">
          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item label="会员名称" :label-width="formLabelWidth">
                <el-input
                  v-model="addForm.realname"
                  autocomplete="off"
                  style="width: 60%"
                  placeholder="请输入会员名称"
                  maxlength="30"
                  show-word-limit
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="手机号码"
                :label-width="formLabelWidth"
                v-enterNumber
                prop="username"
              >
                <el-input
                  v-model="addForm.username"
                  autocomplete="off"
                  style="width: 60%"
                  placeholder="请输入手机号码"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="性别" :label-width="formLabelWidth">
                <template>
                  <el-radio v-model="addForm.gender" :label="10">男</el-radio>
                  <el-radio v-model="addForm.gender" :label="20">女</el-radio>
                  <el-radio v-model="addForm.gender" :label="30">未知</el-radio>
                </template></el-form-item
              ></el-col
            >
            <el-col :span="12">
              <el-form-item
                label="所属门店"
                field_alias="hierarchy_id"
                :label-width="formLabelWidth"
              >
                {{ userInfo.hierarchy_name }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="结婚纪念日" :label-width="formLabelWidth">
                <el-date-picker
                  value-format="yyyy-MM-dd"
                  v-model="addForm.commemoration_time"
                  type="date"
                  style="width: 60%"
                  placeholder="选择结婚纪念日"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="出生日期" :label-width="formLabelWidth">
                <el-date-picker
                  value-format="yyyy-MM-dd"
                  v-model="addForm.birthday_time"
                  type="date"
                  style="width: 60%"
                  placeholder="选择出生日期"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Email" :label-width="formLabelWidth">
                <el-input
                  v-model="addForm.email"
                  autocomplete="off"
                  style="width: 60%"
                  placeholder="请输入Email"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地址" :label-width="formLabelWidth">
                <el-input
                  v-model="addForm.address"
                  autocomplete="off"
                  style="width: 60%"
                  placeholder="请输入地址"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="会员等级" :label-width="formLabelWidth">
                <el-select
                  v-model="addForm.client_level_id"
                  placeholder="请选择会员等级"
                  style="width: 60%"
                >
                  <template v-for="(item, index) in levelList">
                    <el-option
                      :label="item.value"
                      :value="item.client_system_level_id"
                      :key="index"
                    ></el-option> </template
                ></el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="备注" :label-width="formLabelWidth">
                <el-input
                  v-model="addForm.remark"
                  type="textarea"
                  autocomplete="off"
                  style="width: 60%"
                  placeholder="请输入备注"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="添加标签" :label-width="formLabelWidth">
                <div style="display: flex">
                  <el-autocomplete
                    v-model="tagName"
                    :fetch-suggestions="querySearchAsync"
                    placeholder="请输入或者选择标签"
                    @select="handleTags"
                    style="width: 46%"
                  ></el-autocomplete>
                  <el-button
                    type="primary"
                    size="small"
                    style="margin-left: 13px"
                    @click="handleSureTags"
                    >确认</el-button
                  >
                  <!-- <el-select
                    v-model="addForm.parent_id"
                    placeholder="请选择分类标签"
                    @change="handleTags($event)"
                    style="width: 60%; margin-right: 5px"
                  >
                    <template v-for="(item, index) in tagList">
                      <el-option
                        :label="item.tag_name"
                        :value="item.e_client_tags_id"
                        :key="index"
                      ></el-option>
                    </template>
                  </el-select> -->
                </div>
                <div>
                  <div>已添加的标签</div>
                  <div>
                    <el-tag
                      v-for="(tag, index) in dynamicTags"
                      :key="index"
                      closable
                      :disable-transitions="false"
                      style="margin-right: 10px"
                      @close="handleClose(tag)"
                    >
                      {{ tag.tag_name }}
                    </el-tag>
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div style="text-align: center">
          <el-button type="primary" @click="submit('form')" :loading="loading"
            >保存</el-button
          >
          <el-button @click="cancelAdd">取消</el-button>
        </div></el-form
      >
    </el-dialog>
    <el-dialog
      :visible.sync="setIntVisiable"
      width="30%"
      title="设置积分"
      :destroy-on-close="true"
      :before-close="cancelSetInt"
    >
      <el-form :model="setIntForm" :rules="intRules" ref="setIntForm">
        <div class="form">
          <el-form-item
            label="操作类型"
            :label-width="formLabelWidth"
            prop="type"
          >
            <el-radio-group v-model="setIntForm.type">
              <el-radio :label="1">增加</el-radio>
              <el-radio :label="2">扣减</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="积分值"
            :label-width="formLabelWidth"
            prop="integral"
          >
            <el-input
              v-model="setIntForm.integral"
              autocomplete="off"
              style="width: 60%"
              placeholder="请输入积分值"
            ></el-input>
            （最多可输入四位小数）
          </el-form-item>
          <el-form-item label="备注" :label-width="formLabelWidth">
            <el-input
              v-model="setIntForm.remarks"
              type="textarea"
              autocomplete="off"
              style="width: 60%"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
        </div>
        <div style="text-align: center">
          <el-button
            type="primary"
            @click="submitIntegral('setIntForm')"
            :loading="loading"
            >保存</el-button
          >
          <el-button @click="cancelSetInt">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog
      :visible.sync="setSavingsVisiable"
      width="30%"
      title="储值金充值"
      :destroy-on-close="true"
      :before-close="cancelSetSavings"
    >
      <el-form :model="setSavingForm" :rules="savingRules" ref="setSavingForm">
        <div class="form">
          <el-form-item
            label="操作类型"
            :label-width="formLabelWidth"
            prop="type"
          >
            <el-radio-group v-model="setSavingForm.type">
              <el-radio :label="10">增加</el-radio>
              <el-radio :label="20">扣减</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="储值金"
            :label-width="formLabelWidth"
            prop="give_money"
          >
            <el-input
              v-model="setSavingForm.give_money"
              autocomplete="off"
              style="width: 60%"
              placeholder="请输入储值金金额"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" :label-width="formLabelWidth">
            <el-input
              v-model="setSavingForm.remark"
              type="textarea"
              autocomplete="off"
              style="width: 60%"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
        </div>
        <div style="text-align: center">
          <el-button
            type="primary"
            @click="submitSavings('setSavingForm')"
            :loading="loading"
            >保存</el-button
          >
          <el-button @click="cancelSetSavings">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
    <memberDetail
      :showCoupon="showCoupon"
      v-if="isDetails"
      :client_user_id="user_id"
      :hierarchy_id="hierarchy_id"
      :client_user_master_id='hierarchy_id'
      @hide="getList"
      memberType="commonMember"
    />
  </div>
</template>

<script>
import SearchConf from "@/components/searchConf/Index.vue";
import TableConf from "@/components/tableConf/Index.vue";
import ExportConf from "@/components/exportConf/Index.vue";
import Search from "@/components/search/Index.vue";
import storage from "good-storage";
import Cookies from "js-cookie";
import memberDetail from "@/views/member/memberManage/memberDetail/Index.vue";

import {
  commonUserList,
  commonCreateUser,
  commonUpdateUser,
  commonUserExport,
  waitAssignDischargeClient,
  commonSetWaitAssign,
  commonDistrClient,
  commonTakeUser,
  getFollowStoreUser,
  setIntegral,
  insertStorage,
  getImportFile,
} from "@/api/member/memberList/index.js";
import Send from "../../components/Send";
import { getTagList } from "@/api/member/tabManage/index.js";
import { getLevelList } from "@/api/member/integralSetting/index.js";
export default {
  data() {
    let validateNum = (rule, value, callback) => {
      if (!/^\d+(.\d{1,4})?$/.test(value)) {
        callback(new Error("请输入正整数，最多四位小数"));
      } else {
        callback();
      }
    };
    return {
      pickerOptions: {
        disabledDate(time) {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24);
          return time.getTime() > date;
        },
      },
      showCoupon: true,
      isDetails: false,
      assignVisiable: false,
      follow_form: {},
      follow_rules: {
        follow_store_user_id: [
          { required: true, message: "请选择跟进人", trigger: "change" },
        ],
      },
      selectUserList: [],
      exportLoading: false,
      baseUrl: "",
      tableData: [],
      rowList: [],
      total: 0,
      page: 1,
      limit: 15,
      hierarchy_id: null,
      storeList: [],
      tableLoading: false,
      addVisiable: false,
      addForm: {},
      dynamicTags: [],
      type: "add",
      rules: {
        username: [
          { required: true, message: "请填写手机号码", trigger: "blur" },
        ],
        client_level_id: [
          { required: true, message: "请选择会员等级", trigger: "change" },
        ],
      },
      formLabelWidth: "100px",
      loading: false,
      levelList: [],
      tagList: [],
      userInfo: "",
      arr_tag: [],
      client_user_id: [],
      client_user_master_ids:[],
      tagName: "",
      cond: {},
      setIntVisiable: false,
      setSavingsVisiable: false,
      setIntForm: {},
      setSavingForm: {},
      intRules: {
        type: [
          { required: true, message: "请选择操作类型", trigger: "change" },
        ],
        integral: [
          { required: true, message: "请填写积分值", trigger: "blur" },
          { validator: validateNum, trigger: "blur" },
        ],
      },
      savingRules: {
        type: [
          { required: true, message: "请选择操作类型", trigger: "change" },
        ],
        give_money: [
          { required: true, message: "请完善储值金金额", trigger: "blur" },
          { validator: validateNum, trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.token = storage.session.get("token") || Cookies.get("token");
    this.userInfo = storage.get("userInfo");
    const env = process.env.NODE_ENV;
    const host = window.location.host;
    if (env === "development") {
      this.baseUrl = "http://api.dev.etouch.vip";
    } else {
       if (host === "edianbao.dev.etouch.vip") {
        this.baseUrl = "http://api.dev.etouch.vip";
      }
      if (host === "edianbao.test.etouch.vip") {
        this.baseUrl = "https://api.test.etouch.vip";
      }
      if (host === "www.etouch.top") {
        this.baseUrl = "https://api.etouch.top";
      }
      if (host === "edianbao.pretest.etouch.vip") {
        this.baseUrl = "https://api.pretest.etouch.vip";
      }
      if (host === "edianbao.test2.etouch.vip") {
        this.baseUrl = "http://api.test2.etouch.vip";
      }
    }
    this.getList();
  },
  components: {
    SearchConf,
    TableConf,
    ExportConf,
    Search,
    memberDetail,
    Send,
  },
  methods: {
    handleImportSuccess(res, file) {
      if (res.code === 1) {
        if(res.data.data_err.length == 0){
          this.$message({
            type: "success",
            message: "导入成功",
          });
        }
        this.getList();
        if (res.data.data_err.length !== 0) {
          res.data.data_err.forEach((item) => {
            this.$message({
              type: "error",
              message: res.data.data_err.toString(),
            });
          });
        }
      } else {
        this.$message({
          type: "error",
          message: res.msg,
        });
      }
    },
    cancelAssign() {
      this.follow_form = {};
      this.assignVisiable = false;
      this.client_user_id = [];
      this.getList();
    },
    /*
     * 分配会员
     */
    sureAssignUser() {
      this.$refs.follow_form.validate((valid) => {
        if (valid) {
          const data = {
            arr_client_user_id: this.client_user_id,
            follow_store_user_id: this.follow_form.follow_store_user_id,
          };
          commonDistrClient(data).then((res) => {
            if (res.code === 1) {
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.cancelAssign();
              this.getList();
            }
          });
        }
      });
    },
    /*
     *  积分设置
     */
    setIntegral() {
      if (this.client_user_id.length === 0) {
        this.$message({
          type: "warning",
          message: "请选择会员",
        });
      } else {
        this.setIntVisiable = true;
      }
    },
    /*
     *  关闭积分设置弹窗
     */
    cancelSetInt() {
      this.setIntVisiable = false;
      this.setIntForm = {};
      this.client_user_id = [];
      this.getList();
    },
    /*
     *  积分设置
     */
    submitIntegral() {
      this.$refs.setIntForm.validate((valid) => {
        if (valid) {
          const data = this.setIntForm;
          data.client_user_id = this.client_user_id;
          setIntegral(data).then((res) => {
            if (res.code === 1) {
              this.$message({
                type: "success",
                message: "积分设置成功!",
              });

              this.cancelSetInt();
            }
          });
        }
      });
    },
    /*
     *  关闭储值金充值弹窗
     */
    cancelSetSavings() {
      this.setSavingsVisiable = false;
      this.setSavingForm = {};
      this.client_user_id = [];
      this.getList();
    },
    /*
     *  储值金充值
     */
    setSavings() {
      if (this.client_user_id.length === 0) {
        this.$message({
          type: "warning",
          message: "请选择会员",
        });
      } else {
        this.setSavingsVisiable = true;
      }
    },
    /*
     *  储值金充值
     */
    submitSavings() {
      this.$refs.setSavingForm.validate((valid) => {
        if (valid) {
          const data = this.setSavingForm;
          data.client_user_id = JSON.stringify(this.client_user_id);
          insertStorage(data).then((res) => {
            if (res.code === 1) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              this.client_user_id = [];
              this.cancelSetSavings();
            }
          });
        }
      });
    },
    /*
     *  新增会员
     */
    handleAdd() {
      this.addVisiable = true;
      this.type = "add";
      this.getTagList();
      this.getLevelList();
    },
    cancelAdd() {
      this.addVisiable = false;
      this.addForm = {};
      this.arr_tag = [];
      this.dynamicTags = [];
      this.client_user_id = [];
      this.getList();
    },
    // 处理表格数据
    handleRowList(rowList) {
      this.rowList = rowList.filter((item) => item.field_alias !== "avatar");
    },
    /*
     *  获取标签分类数据
     */
    getTagList() {
      getTagList({ hierarchy_id: this.userInfo.hierarchy_id }).then((res) => {
        if (res.code === 1) {
          this.tagList = res.data.map((item) => {
            item.value = item.tag_name;
            return item;
          });
        }
      });
    },
    querySearchAsync(queryString, cb) {
      var restaurants = this.tagList;
      var results = queryString
        ? restaurants.filter(this.createStateFilter(queryString))
        : restaurants;
      cb(results);
    },

    createStateFilter(queryString) {
      return (state) => {
        return (
          state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    handleAssignMember() {
      if (this.client_user_id.length === 0) {
        this.$message({
          type: "warning",
          message: "请选择会员",
        });
      } else {
        this.assignVisiable = true;
        this.selectUser();
      }
    },
    /*
     *  获取跟进人数据
     */
    selectUser() {
      const data = {
        status: 10,
      };
      getFollowStoreUser(data).then((res) => {
        if (res.code === 1) {
          this.selectUserList = res.data;
        }
      });
    },
    handleSetWaitAssign() {
      if (this.client_user_id.length === 0) {
        this.$message({
          type: "warning",
          message: "请选择会员",
        });
      } else {
        this.$confirm("是否确定将已选会员设为待分配会员？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            commonSetWaitAssign({
              arr_client_user_id: this.client_user_id,
            })
              .then((res) => {
                if (res.code === 1) {
                  this.$message({
                    type: "success",
                    message: "操作成功",
                  });
                  this.client_user_id = [];
                  this.getList();
                } else {
                  this.client_user_id = [];
                  this.getList();
                }
              })
              .catch(() => {
                this.client_user_id = [];
                this.getList();
              });
          })
          .catch(() => {
            this.client_user_id = [];
            this.getList();
          });
      }
    },
    handleTakeUser() {
      if (this.client_user_id.length === 0) {
        this.$message({
          type: "warning",
          message: "请选择会员",
        });
      } else {
        this.$confirm("是否确定领取已选会员跟进？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            commonTakeUser({
              arr_client_user_id: this.client_user_id,
            })
              .then((res) => {
                if (res.code === 1) {
                  this.$message({
                    type: "success",
                    message: "操作成功",
                  });
                  this.client_user_id = [];
                  this.getList();
                } else {
                  this.client_user_id = [];
                  this.getList();
                }
              })
              .catch(() => {
                this.client_user_id = [];
                this.getList();
              });
          })
          .catch(() => {
            this.client_user_id = [];
            this.getList();
          });
      }
    },
    /*
     *  获取选择标签
     */
    handleTags(event) {
      this.tagList.forEach((item) => {
        if (item.e_client_tags_id === event.e_client_tags_id) {
          this.dynamicTags.push({
            isadd: true,
            e_client_tags_id: event.e_client_tags_id,
            tag_name: item.tag_name,
          });
        }
      });
      let newArr = [];
      let obj = {};
      this.dynamicTags.forEach((item) => {
        const { e_client_tags_id } = item;
        if (!obj[e_client_tags_id]) {
          obj[e_client_tags_id] = true;
          newArr.push(item);
        }
      });
      this.dynamicTags = newArr;
      console.log(this.dynamicTags);
      this.tagName = "";
    },
    handleSureTags() {
      if (this.tagName === "") {
        this.$message({
          message: "请输入标签名",
          type: "info",
        });
      } else {
        if (
          this.dynamicTags.filter((item) => item.tag_name === this.tagName)
            .length === 0
        ) {
          this.dynamicTags.push({
            e_client_tags_id: 0,
            tag_name: this.tagName,
          });
          this.tagName = "";
        }
      }
    },
    /*
     *  保存会员信息
     */
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let arr = [];
          arr = this.dynamicTags.map((item) => {
            const info = {};
            info.key = item.e_client_tags_id;
            info.value = item.tag_name;
            return info;
          });
          console.log(arr);
          const data = this.addForm;
          delete data.e_client_tags_id;
          delete data.parent_id;
          if (this.type === "add") {
            commonCreateUser({
              ...data,
              arr_tag: arr,
            }).then((res) => {
              if (res.code === 1) {
                this.$message({
                  message: "新增成功！",
                  type: "success",
                });
                if (this.onlyAdd) {
                  this.$emit("onHideAdd", res.data);
                } else {
                  this.cancelAdd();
                }
              }
            });
          } else {
            delete data.username;
            delete data.str_tag_name;
            commonUpdateUser({ ...data, arr_tag: arr }).then((res) => {
              if (res.code === 1) {
                this.$message({
                  message: "更新成功！",
                  type: "success",
                });
                this.cancelAdd();
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    /*
     *  删除所选标签
     */
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    /*
     *  获取会员等级下拉数据
     */
    getLevelList(id) {
      const data = {
        is_limit: 10,
        hierarchy_id: id,
      };
      getLevelList(data).then((res) => {
        if (res.code === 1) {
          this.levelList = res.data;
        }
      });
    },
    /*
     *  导出
     */
    handleExport() {
      if (JSON.stringify(this.cond) === "{}") {
        this.cond = {
          page: 1,
          limit: this.total,
        };
      }
      const data = {
        ...this.cond,
        function_code: "clientuser",
      };
      this.exportLoading = true;
      commonUserExport(data)
        .then((res) => {
          const blob = new Blob([res], { type: "application/vnd.ms-excel" });
          const a = document.createElement("a");
          a.setAttribute("download", `公海会员列表导出.xlsx`);
          a.href = URL.createObjectURL(blob);
          a.click();
          this.exportLoading = false;
        })
        .catch((res) => {
          this.$message({
            type: "error",
            message: res.msg,
          });
          this.exportLoading = false;
        });
    },
    handleDalclick(row) {
      this.user_id = row.client_user_id;
      this.isDetails = true;
    },
    getList() {
      this.isDetails = false;
      const data = {
        ...this.cond,
        page: this.page,
        limit: this.limit,
      };
      commonUserList(data).then((res) => {
        if (res.code === 1) {
          this.tableLoading = false;
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    handleDalclick(row) {
      this.isDetails = true;
      this.user_id = row.client_user_id;
      this.client_user_master_id=row.client_user_master_id
      this.hierarchy_id=row.hierarchy_id;
    },
    handleDetails(event, row) {
      this.user_id = row.client_user_id;
      if (event === 2) {
        this.addVisiable = true;
        this.addForm = row;
        this.dynamicTags = row.str_tag.map((item) => {
          const info = {
            e_client_tags_id: item.key,
            tag_name: item.value,
          };
          return info;
        });
        this.type = "edit";
        this.getTagList();
        this.getLevelList();
      }
      if (event === 1) {
        this.isDetails = true;
        this.client_user_master_id=row.client_user_master_id
        this.hierarchy_id=row.hierarchy_id;
      }
    },
    handleSelectionChange(val) {
      let arr = [];
      val.forEach((item) => {
        arr.push(item.client_user_id);
      });
      this.client_user_id = [...new Set(arr)];
      let arr2=[];
      val.forEach((item) => {
        arr2.push(item.client_user_master_id);
      });
      this.client_user_master_ids = [...new Set(arr2)];
    },

    handleCond(cond) {
      this.cond = { ...cond };
      this.page = 1;
      this.getList();
    },
    getImportFile() {
      getImportFile({ hierarchy_id: this.userInfo.hierarchy_id }).then(
        (res) => {
          var filename = "导入模板.csv";
          res = "\ufeff" + res;
          var blob = new Blob([res], { type: "application/vnd.ms-excel" });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
          } else {
            var urlCreator = window.URL || window.wibkitURL;
            var url = urlCreator.createObjectURL(blob);
            var a = document.createElement("a");
            a.download = filename;
            a.href = url;
            a.click();
          }
        }
      );
    },
    handleSizeChange(val) {
      this.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
</style>
